<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="11">
          <br>
          <div class="text-center">
            <h2>UFC FIGHT NIGHT</h2>
            <h3>Costa vs Vettori</h3>
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{
                start_date|moment("dddd MMM Do")
              }}</span>
              <span class="d-block text-truncate">{{
                start_date|moment("h:mm a")
              }} <small>{{ tz }}</small></span>
            </div>

            <p class="text-sm mt-5">
              Pick who you think will win.<br>
              You'll get a 3x multiplier on the result for players your own.<br>
              Captains get double any points earned.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-divider class="pb-6"></v-divider>

      <div
        v-if="!rosterLoaded"
        class="mb-12"
      >
        <v-row>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3 offset-md-1"
          >
            <div class="text-center">
              <v-skeleton-loader
                v-if="!rosterLoaded"
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3 offset-md-1"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3 offset-md-1"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3 offset-md-1"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        v-for="(item, index) in roster"
        :key="index"
      >
        <v-row>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3 offset-md-1"
          >
            <div class="text-center">
              <v-skeleton-loader
                v-if="!item.fighter_1_data || !rosterLoaded"
                type="article"
              ></v-skeleton-loader>

              <v-card
                v-if="item.fighter_1_data"
                class="text-center"
                :class="{ 'chosen': item.picked === 'fighter_1'}"
                @click="selectToWin(index, '1')"
              >
                <v-card-text>
                  <h3 class="text-center d-all mb-2">
                    {{ item.fighter_1 }}
                  </h3>
                  {{ item.fighter_1_data_chosen }}
                  <v-avatar
                    rounded
                    class="text-center"
                    size="80"
                  >
                    <v-img
                      :src="item.fighter_1_data.head_shot_url||require('@/assets/images/fighters/default-profile-head.png')"
                    ></v-img>
                  </v-avatar>
                  <br>
                  <v-chip class="mt-4 hidden-sm-and-down text-xs">
                    Wins:{{ item.fighter_1_data.win }} Losses:{{ item.fighter_1_data.loss }}
                    Draws:{{ item.fighter_1_data.draw }}
                  </v-chip>
                  <div
                    v-if="item.fight_results"
                    class="mt-2"
                  >
                    <v-chip
                      v-if="item.fight_results.winner == 'fighter_1' && !item.fight_results.draw"
                      outlined
                      color="success"
                    >
                      Won!
                    </v-chip>

                    <v-chip
                      v-if="item.fight_results.winner == 'fighter_2' && !item.fight_results.draw"
                      outlined
                      color="error"
                    >
                      Lost!
                    </v-chip>
                    <br>

                    <v-chip
                      v-if="item.fight_results.draw"
                      class="mt-2"
                      outlined
                      color="primary"
                    >
                      Draw
                    </v-chip>

                    <v-chip
                      v-if="item.fight_results.bonus == 'Fight of the Night'"
                      class="mt-2"
                      outlined
                      color="warning"
                    >
                      {{ item.fight_results.bonus }} Bonus: {{ item.fight_results.bonusAmount|toCurrency }}
                    </v-chip>

                    <v-chip
                      v-if="item.fight_results.bonus == 'Performance of the Night' && item.fight_results.winner == 'fighter_1'"
                      class="mt-2"
                      outlined
                      color="warning"
                    >
                      {{ item.fight_results.bonus }} Bonus: {{ item.fight_results.bonusAmount|toCurrency }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
              <v-btn
                v-if="user.profile.admin"
                x-small
                outlined
                @click="addUpdateFighter(item.fighter_1_id)"
              >
                Auto Add/Update
              </v-btn>

              <v-btn
                v-if="user.profile.admin"
                x-small
                class="ml-2"
                outlined
                @click="editResultModal(item, index, 1)"
              >
                Add Results
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="0"
            class="hidden-sm-and-down col-sm-0 col-md-3"
          >
            <div class="text-center mt-4">
              <h2 v-if="item.main_event">
                Main Event
              </h2>
              {{ item.weight_class }}
            </div>
            <h1 class="mt-2 text-center">
              vs
            </h1>
            <div
              v-if="item.fight_results"
              class="mt-2 text-center"
            >
              <v-chip
                v-if="item.fight_results.draw"
                outlined
              >
                Draw
              </v-chip>
              <v-chip
                v-if="!item.fight_results.draw"
                outlined
              >
                {{ item[item.fight_results.winner] }} won by "{{ item.fight_results.method }}"
              </v-chip>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="col-sm-6 col-md-3"
          >
            <div class="text-center">
              <v-skeleton-loader
                v-if="!item.fighter_1_data || !rosterLoaded"
                type="article"
              ></v-skeleton-loader>

              <v-card
                v-if="item.fighter_2_data"
                class="text-center"
                :class="{ 'chosen': item.picked==='fighter_2'}"
                @click="selectToWin(index, '2')"
              >
                <v-card-text>
                  <h3 class="text-center d-all mb-2">
                    {{ item.fighter_2 }}
                  </h3>
                  {{ item.fighter_2_data_chosen }}
                  <v-avatar
                    rounded
                    class="text-center"
                    size="80"
                  >
                    <v-img
                      :src="item.fighter_2_data.head_shot_url||require('@/assets/images/fighters/default-profile-head.png')"
                    ></v-img>
                  </v-avatar>
                  <br>
                  <v-chip class="mt-4 hidden-sm-and-down text-xs">
                    Wins:{{ item.fighter_2_data.win }} Losses:{{ item.fighter_2_data.loss }}
                    Draws:{{ item.fighter_2_data.draw }}
                  </v-chip>
                  <div
                    v-if="item.fight_results"
                    class="mt-2"
                  >
                    <v-chip
                      v-if="item.fight_results.winner == 'fighter_2' && !item.fight_results.draw"
                      class="mt-2"
                      outlined
                      color="success"
                    >
                      Won!
                    </v-chip>
                    <v-chip
                      v-if="item.fight_results.winner == 'fighter_1' && !item.fight_results.draw"
                      class="mt-2"
                      outlined
                      color="error"
                    >
                      Lost!
                    </v-chip>
                    <v-chip
                      v-if="item.fight_results.draw"
                      class="mt-2"
                      outlined
                      color="primary"
                    >
                      Draw
                    </v-chip>

                    <v-chip
                      v-if="item.fight_results.bonus == 'Fight of the Night'"
                      class="mt-2"
                      outlined
                      color="warning"
                    >
                      {{ item.fight_results.bonus }} Bonus: {{ item.fight_results.bonusAmount|toCurrency }}
                    </v-chip>

                    <v-chip
                      v-if="item.fight_results.bonus == 'Performance of the Night' && item.fight_results.winner == 'fighter_2'"
                      class="mt-2"
                      color="success"
                    >
                      {{ item.fight_results.bonus }} Bonus: {{ item.fight_results.bonusAmount|toCurrency }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
              <v-btn
                v-if="user.profile.admin"
                x-small
                outlined
                @click="addUpdateFighter(item.fighter_2_id)"
              >
                Auto Add/Update
              </v-btn>

              <v-btn
                v-if="user.profile.admin"
                x-small
                class="ml-2"
                outlined
                @click="editResultModal(item, index, 2)"
              >
                Add Results
              </v-btn>
            </div>
          </v-col>
          <span v-if="!item.fighter_1_data && !user.profile.admin">{{ item.fighter_1 }}</span>
          <v-col cols="12">
            <v-divider class="pb-5"></v-divider>
          </v-col>
        </v-row>
      </div>
      <div class="picksCompleted">
        <div v-if="eventStatus == 'Upcoming'">
          <div
            v-if="!picksDone"
            class="text-center picktext"
          >
            <v-chip large>
              You 've made picks in
              <v-chip class="mx-2">
                {{ selected }}/{{ roster.length }}
              </v-chip>
              fights.
            </v-chip>
          </div>

          <div
            v-if="picksDone"
            class="text-center"
          >
            <v-chip
              large
              color="success"
              outlined
            >
              You've completed your picks, good luck!
            </v-chip>
          </div>
        </div>

        <div v-if="eventStatus == 'Started'">
          <v-chip
            large
            color="success"
          >
            Event in Progress.
          </v-chip>
        </div>
        <div v-if="eventStatus == 'Finished'">
          <v-chip
            large
            color="success"
          >
            Event has finished, results pending.
          </v-chip>
        </div>
        <div v-if="eventStatus == 'Completed'">
          <v-chip
            large
            color="success"
          >
            Event Completed.
          </v-chip>
        </div>
      </div>
    </v-card>

    <v-dialog
      v-model="edit"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Fight Results</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="selectedOutcome"
                  :items="['','win','loss','draw']"
                  label="Result"
                  required
                ></v-autocomplete>
                <v-autocomplete
                  v-model="selectedFightResult.method"
                  :items="['','Decision','KnockOut','Submission']"
                  label="Method"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="selectedFightResult.bonus"
                  :items="['','Fight of the Night','Performance of the Night','Bonus']"
                  label="Bonuses"
                  required
                ></v-autocomplete>
                <v-col
                  cols="12"
                >
                  <v-currency-field
                    v-model="selectedFightResult.bonusAmount"
                    label="Bonus Amount"
                    @blur="selectedFightResult.bonusAmount = defaultBonusAmount"
                  >
                  </v-currency-field>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit = false"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click="saveResult()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiAlert} from '@mdi/js'
import Vue2Filters from 'vue2-filters'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import dbFunctions from '@/db'

export default {
  mixins: [Vue2Filters.mixin],
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      rosterLoaded: false,
      picksDone: false,
      fromSaved: false,
      myPicks: {},
      start_date: '2021-10-23T20:00:00+00:00',
      selected: 0,
      selectedPosition: 0,
      selectedOutcome: '',
      selectedFightResult: {},
      ufcEvent: {},
      fighters: {},
      edit: false,
      searchField: 'date',
      eventId: 'October 23, 2021',
      eventSlug: '',
      eventStatus: '',
      finalResults: false,
      defaultBonusAmount: 50000,
    }
  },
  computed: {
    roster() {
      if (this.fromSaved) {
        return this.ufcEvent
      }

      return this.orderBy(this.ufcEvent, 'order')
    },
  },
  mounted() {
    this.checkEvent()
    this.getEvents()
  },
  methods: {
    checkEvent() {
      if (this.finalResults) {
        this.eventStatus = 'Completed'
      } else if (this.$moment(this.start_date) > this.$moment()) {
        this.eventStatus = 'Upcoming'
      } else if (this.$moment(this.start_date) < this.$moment()) {
        this.eventStatus = 'Started'
        if (this.$moment(this.start_date).add(5, 'hours') < this.$moment()) {
          this.eventStatus = 'Finished'
        }
      }
    },
    async processEvents() {
      for (let i = 0; i < this.roster.length; i += 1) {
        this.getRosterFighter(i, this.roster[i].fighter_1_id, '1')
        this.getRosterFighter(i, this.roster[i].fighter_2_id, '2')
      }
      this.rosterLoaded = true
      this.getSavedPicks()
    },
    async getEvents() {
      console.log(this.$route.query.eventId)
      if (this.$route.query.eventId) {
        this.eventId = this.$route.query.eventId
      }
      await dbFunctions.searchUFCEvent(this.searchField, this.eventId)
        .then(eventData => {
          // eslint-disable-next-line prefer-destructuring
          this.ufcEvent = eventData
          this.processEvents()
        })
    },
    async getSavedPicks() {
      this.eventSlug = this.$moment(this.eventId).format('MMMM-Do-YYYY')
      await dbFunctions.getPicks(this.user.uid, this.eventSlug)
        .then(eventData => {
          if (eventData) {
            this.myPicks = eventData.data
            if (this.myPicks) {
              console.log('check saved picks')
              for (let i = 0; i < this.myPicks.length; i += 1) {
                if (this.myPicks[i].picked) {
                  this.$set(this.roster[i], 'picked', this.myPicks[i].picked)
                }
              }
            }
            this.checkPicks()
          }
        })
    },
    getRosterFighter(index, fighterID, num) {
      dbFunctions.getBaseFighter(fighterID)
        .then(data => {
          this.$set(this.roster[index], [`fighter_${num}_data`], data)
        })
    },
    selectToWin(index, num) {
      if (this.eventStatus === 'Upcoming') {
        this.$set(this.roster[index], 'picked', false)
        this.$set(this.roster[index], 'picked', `fighter_${num}`)
        dbFunctions.savePickem(this.user.uid, this.eventSlug, this.roster)
        this.checkPicks()
      }
    },
    async checkPicks() {
      // eslint-disable-next-line no-plusplus
      let totalPicked = 0
      console.log('check picks')
      for (let i = 0; i < this.roster.length; i += 1) {
        if (this.roster[i].picked) {
          totalPicked += 1
        }
      }
      this.selected = totalPicked
      if (this.selected >= this.roster.length) {
        this.picksDone = true
      } else {
        this.picksDone = false
      }
    },

    editResultModal(item, index, position) {
      this.edit = true
      this.selectedIndex = index
      this.selectedPosition = position
    },
    saveResult() {
      this.selectedFightResult.draw = false
      if (this.selectedOutcome === 'draw') {
        this.selectedFightResult.draw = true
      } else if (this.selectedOutcome === 'win') {
        this.selectedFightResult.winner = `fighter_${this.selectedPosition}`
      } else if (this.selectedOutcome === 'loss') {
        if (this.selectedPosition === 1) {
          this.selectedFightResult.winner = 'fighter_2'
        } else {
          this.selectedFightResult.winner = 'fighter_1'
        }
      }
      this.$set(this.roster[this.selectedIndex], ['fight_results'], this.selectedFightResult)
      console.log(this.roster[this.selectedIndex].id)
      dbFunctions.updateUFCEvent(this.roster[this.selectedIndex].id, {fight_results: this.selectedFightResult})
      this.selectedFightResult = {
        draw: false,
        winner: '',
        method: '',
        bonus: '',
        bonusAmount: 0,
      }
    },
    addUpdateFighter(fighterId) {
      console.log('update fighter')
      this.addLoading = true
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get(`fighter/?id=${fighterId}`).then(response => {
        this.addLoading = false
        const fighterData = response.data.fighter[0]
        dbFunctions.getBaseFighter(fighterId)
          .then(fdata => {
            console.log(fdata.token_template_id)
            if (fdata.token_template_id) {
              fighterData.token_template_id = fdata.token_template_id
              console.log('template sync')
              console.log(fighterData)
              const startSyncTemplate = firebase.functions().httpsCallable('syncTemplates')
              startSyncTemplate(fighterData)
                .then(() => {
                  dbFunctions.syncFighterBaseMintedTemplate(fighterData)
                    .then(() => {
                      console.log('sync complete')
                    })
                })
                .catch(err => {
                  console.log(err)
                })
            } else {
              console.log('no template id')
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
.v-card.chosen {
  border: 4px solid #1abadb;
}

.picksCompleted {
  position: fixed;
  bottom: -40px;
  transform: translate(-50%, -50%);
  background: #312d4b;
  color: #fff;
  left: 53%;
  width: 100%;
  text-align: center;
  padding: 15px 0 15px 0;
  box-shadow: 2px -1px 8px #555;
}

.picksCompleted .v-chip.v-size--large {
  height: 45px;
}
</style>
